import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { commonMessages } from "@temp/intl";
import classNames from "classnames";
import { RichTextContent } from "@components/atoms";
import { isEmpty } from "lodash";
import { generateCategoryUrl } from "@utils/core";
import { maybe } from "@temp/core/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProductListHeader } from "@components/molecules";
import { sanitize } from "dompurify";
import draftToHtml from "draftjs-to-html";
import nophoto from "../../images/nophoto.png";
import { Breadcrumbs, extractBreadcrumbs, ProductsFeatured } from "../../components";
import { FilterSidebar, ProductList } from "../../@next/components/organisms";
import noPhotoImg from "../../images/no-photo.svg";
import * as S from "../../@next/components/molecules/ProductDescription/styles";

var Page = function Page(_ref) {
  var category = _ref.category,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      clearFilters = _ref.clearFilters,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  return React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: extractBreadcrumbs(category)
  }), category.backgroundImage && category.backgroundImage.url && React.createElement(Link, {
    to: generateCategoryUrl(category.id, category.name),
    key: category.id
  }, React.createElement("div", {
    className: classNames("home-page__categories__list__image", {
      "home-page__categories__list__image--no-photo": !category.backgroundImage
    }),
    style: {
      backgroundImage: "url(".concat(category.backgroundImage ? category.backgroundImage.url : noPhotoImg, ")")
    }
  })), category.showSubCategoriesOnDetailScreen && React.createElement(Swiper, {
    className: "category-swiper",
    slidesPerView: 4,
    spaceBetween: 40,
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      550: {
        slidesPerView: 2
      },
      820: {
        slidesPerView: 3
      },
      1080: {
        slidesPerView: 4
      }
    },
    navigation: true
  }, category && category.children.edges.map(function (edge) {
    var _edge$node = edge.node,
        id = _edge$node.id,
        name = _edge$node.name,
        backgroundImage = _edge$node.backgroundImage;
    return React.createElement("div", null, React.createElement(SwiperSlide, {
      className: "category-swiper-swiper-slide",
      key: id
    }, React.createElement(Link, {
      to: generateCategoryUrl(id, name),
      key: id
    }, backgroundImage ? React.createElement("img", {
      className: "category-swiper-swiper-slide-swiper-image",
      src: backgroundImage === null || backgroundImage === void 0 ? void 0 : backgroundImage.url,
      alt: id
    }) : React.createElement("img", {
      className: "category-swiper-swiper-slide-swiper-noimage",
      src: nophoto,
      alt: id
    }))));
  })), !isEmpty(JSON.parse(category.descriptionJson)) && sanitize(draftToHtml(JSON.parse(category.descriptionJson))).length >= 50 && React.createElement(S.Description, null, React.createElement(RichTextContent, {
    descriptionJson: category.translation && category.translation.descriptionJson || category.descriptionJson
  })), React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), React.createElement(ProductListHeader, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), canDisplayProducts && React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  })), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;