import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAuth, useCart } from "@saleor/sdk";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { useLanguage } from "gqlTypes/globalTypes";
import { MenuDropdown, Offline, Online, OverlayContext, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import NavDropdown from "./NavDropdown";
import { TypedMainMenuQuery } from "./queries";
import cartImg from "../../images/cart.svg";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import logoImg from "../../images/logo.svg";
import searchImg from "../../images/search.svg";
import userImg from "../../images/user.svg";
import { mediumScreen, smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

var MainMenu = function MainMenu() {
  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useCart = useCart(),
      items = _useCart.items;

  var language = useLanguage();

  var handleSignOut = function handleSignOut() {
    signOut();
  };

  var cartItemsQuantity = items && items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0;
  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement("nav", {
      className: "main-menu",
      id: "header"
    }, React.createElement("div", {
      className: "main-menu__left"
    }, React.createElement(TypedMainMenuQuery, {
      variables: {
        languageCode: language.toUpperCase()
      },
      renderOnError: true,
      displayLoader: false
    }, function (_ref) {
      var data = _ref.data;
      var items = maybe(function () {
        return data.shop.navigation.main.items;
      }, []);
      return React.createElement("ul", null, React.createElement(Media, {
        query: {
          maxWidth: mediumScreen
        },
        render: function render() {
          return React.createElement("li", {
            "data-test": "toggleSideMenuLink",
            className: "main-menu__hamburger",
            onClick: function onClick() {
              return overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
                data: items
              });
            }
          }, React.createElement(ReactSVG, {
            path: hamburgerImg,
            className: "main-menu__hamburger--icon"
          }), React.createElement(ReactSVG, {
            path: hamburgerHoverImg,
            className: "main-menu__hamburger--hover"
          }));
        }
      }), React.createElement(Media, {
        query: {
          minWidth: mediumScreen
        },
        render: function render() {
          return items.map(function (item) {
            return React.createElement("li", {
              "data-test": "mainMenuItem",
              className: "main-menu__item",
              key: item.id
            }, React.createElement(NavDropdown, _extends({
              overlay: overlayContext
            }, item)));
          });
        }
      }), React.createElement(Online, null, React.createElement(Media, {
        query: {
          maxWidth: smallScreen
        },
        render: function render() {
          return React.createElement(React.Fragment, null, user ? React.createElement(MenuDropdown, {
            suffixClass: "__rightdown",
            head: React.createElement("li", {
              className: "main-menu__icon main-menu__user--active"
            }, React.createElement(ReactSVG, {
              path: userImg
            })),
            content: React.createElement("ul", {
              className: "main-menu__dropdown"
            }, React.createElement("li", {
              "data-test": "mobileMenuMyAccountLink"
            }, React.createElement(Link, {
              to: appPaths.accountUrl
            }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
              "data-test": "mobileMenuOrderHistoryLink"
            }, React.createElement(Link, {
              to: appPaths.orderHistoryUrl
            }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
              "data-test": "mobileMenuAddressBookLink"
            }, React.createElement(Link, {
              to: appPaths.addressBookUrl
            }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
              onClick: handleSignOut,
              "data-test": "mobileMenuLogoutLink"
            }, React.createElement(FormattedMessage, commonMessages.logOut)))
          }) : React.createElement("li", {
            "data-test": "mobileMenuLoginLink",
            className: "main-menu__icon",
            onClick: function onClick() {
              return overlayContext.show(OverlayType.login, OverlayTheme.left);
            }
          }, React.createElement(ReactSVG, {
            path: userImg
          })));
        }
      })));
    })), React.createElement("div", {
      className: "main-menu__center"
    }, React.createElement(Link, {
      to: appPaths.baseUrl
    }, React.createElement(ReactSVG, {
      path: logoImg
    }))), React.createElement("div", {
      className: "main-menu__right"
    }, React.createElement("ul", null, React.createElement(Online, null, React.createElement(Media, {
      query: {
        minWidth: smallScreen
      },
      render: function render() {
        return React.createElement(React.Fragment, null, user ? React.createElement(MenuDropdown, {
          head: React.createElement("li", {
            className: "main-menu__icon main-menu__user--active"
          }, React.createElement(ReactSVG, {
            path: userImg
          })),
          content: React.createElement("ul", {
            className: "main-menu__dropdown"
          }, React.createElement("li", {
            "data-test": "desktopMenuMyAccountLink"
          }, React.createElement(Link, {
            to: appPaths.accountUrl
          }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
            "data-test": "desktopMenuOrderHistoryLink"
          }, React.createElement(Link, {
            to: appPaths.orderHistoryUrl
          }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
            "data-test": "desktopMenuAddressBookLink"
          }, React.createElement(Link, {
            to: appPaths.addressBookUrl
          }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
            onClick: handleSignOut,
            "data-test": "desktopMenuLogoutLink"
          }, React.createElement(FormattedMessage, commonMessages.logOut)))
        }) : React.createElement("li", {
          "data-test": "desktopMenuLoginOverlayLink",
          className: "main-menu__icon",
          onClick: function onClick() {
            return overlayContext.show(OverlayType.login, OverlayTheme.right);
          }
        }, React.createElement(ReactSVG, {
          path: userImg
        })));
      }
    }), React.createElement("li", {
      "data-test": "menuCartOverlayLink",
      className: "main-menu__icon main-menu__cart",
      onClick: function onClick() {
        overlayContext.show(OverlayType.cart, OverlayTheme.right);
      }
    }, React.createElement(ReactSVG, {
      path: cartImg
    }), cartItemsQuantity > 0 ? React.createElement("span", {
      className: "main-menu__cart__quantity"
    }, cartItemsQuantity) : null)), React.createElement(Offline, null, React.createElement("li", {
      className: "main-menu__offline"
    }, React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("span", null, React.createElement(FormattedMessage, {
          id: "components.MainMenu.MainMenu.4144939532",
          defaultMessage: "Offline"
        }));
      }
    }))), React.createElement("li", {
      "data-test": "menuSearchOverlayLink",
      className: "main-menu__search",
      onClick: function onClick() {
        return overlayContext.show(OverlayType.search, OverlayTheme.right);
      }
    }, React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.search));
      }
    }), React.createElement(ReactSVG, {
      path: searchImg
    })))));
  });
};

export default MainMenu;